footer {
    background-color: #000000;
    color: white;
    font-family: "Poppins", sans-serif;
    padding: 1rem;
}

.left-section {
    text-align: left;
    margin-bottom: 1rem;
}

.right-section {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.contact p {
    margin: 0.5rem 0;
}

#emailInput {
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 25px;
    border: none;
}

.bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.social-icons {
    margin-bottom: 1.5rem;
}

.social-icons img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    cursor: pointer;
}

.logo {
    width: 9rem;
    height: auto;
    object-fit: contain;
}

.divider {
    display: flex;
    flex-direction: column;
}

.subscribe .notify {
    font-size: 14;
    font-weight: 700;
    margin: 0;
}
.subscribe .desc {
    font-size: 11;
    margin: 0;
}
.input-group {
    width: 18.75rem !important;
}

/* Responsive Styles */

@media (min-width: 768px) {
    footer {
        background-color: #000000;
        color: white;
        font-family: "Poppins", sans-serif;
    }

    .left-section {
        flex: 50%;
        float: left;
        text-align: left;
        margin-right: 20px;
    }
    .right-section {
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: last baseline;
        margin-top: 0;
    }

    .right-section p {
        margin: 0;
    }

    .contact p {
        margin: 5px 0;
    }
    #emailInput {
        width: 50%;
        padding: 5px;
        margin-top: 5px;
        border-radius: 25px;
        border: none;
    }

    .bottom-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        clear: both;
        margin-top: 3rem;
        border-top: 1px solid grey;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }
    .social-icons {
        margin-bottom: 0;
    }

    .social-icons img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        cursor: pointer;
    }

    .logo {
        width: 9rem;
        height: 4rem;
        object-fit: contain;
    }

    .divider {
        display: flex;
        flex-direction: row;
    }

    .subscribe .notify {
        font-size: 14;
        font-weight: 700;
    }
    .subscribe .desc {
        font-size: 11;
    }
    .input-width {
        width: 100%;
    }
}
