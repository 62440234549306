.explore {
    overflow: auto;
    scroll-snap-type: y mandatory;
    height: 100vh;
}

.car_img {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    height: 100%;
    margin: auto;
    object-fit: contain;
    transform: translateY(33%);
    z-index: 50;
    pointer-events: none;
}

@media (max-width: 768px) {
    .car_img {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 75vh;
        object-fit: contain;
        margin: 0;
        transform: translateY(43%);
        z-index: 50;
        pointer-events: none;
    }
}
