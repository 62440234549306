.mobile_explore {
    overflow: auto;
    scroll-snap-type: y mandatory;
    height: 100vh;
}

.kingsmen_mobile_car {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: contain;
    z-index: 50;
    pointer-events: none;
    transform: translateY(1.5%);
}
