.wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 0;
}
#animation_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 50;
}

#animation_img_on {
    width: 100%;
    height: 100%;
    object-fit: contain;
    animation: moveDown 2.5s forwards;
    z-index: 50;
}

.explore_button_faded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 15rem;
    opacity: 0;
    z-index: 100;
    pointer-events: none;
    transform: translateY(-80%);
}

.explore_button_faded_visible {
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 15rem;
    transition: opacity 3s;
    z-index: 100;
    transform: translateY(-80%);
}
.scroll_icon {
    width: 7rem;
    height: 4rem;
    object-fit: contain;
    opacity: 0.5;
}

.info_guide {
    position: absolute;
    bottom: 10%;
    z-index: 250;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info_guide_hide {
    position: absolute;
    bottom: 10%;
    z-index: 250;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 1s;
    opacity: 0;
}

.info_guide > p {
    font-family: "Manrope" sans-serif;
    letter-spacing: 5px;
    color: #ffffff;
    margin: 0;
    font-size: 1rem;
}

@keyframes moveDown {
    0% {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    100% {
        transform: translateY(25%) scale(1.5);
    }
}

@keyframes slideInFromBottom {
    0% {
        bottom: -100px; /* Start position below the screen */
        z-index: 25;
    }
    100% {
        bottom: 50%; /* Final position at the vertical center */
        z-index: 75;
    }
}
.gradient-text {
    background: linear-gradient(to bottom, #ffffff, #000000);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-size: 3.5rem;
    margin: 0;
    font-family: "Manrope", sans-serif;
    letter-spacing: 5px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    #animation_img,
    #animation_img_on {
        width: 100%;
        height: 50%;
        padding: 15px;
        object-fit: cover;
        z-index: 50;
        animation: movedown 2.5s forwards;
    }
    .explore_button_faded,
    .explore_button_faded_visible {
        position: absolute;
        top: 40%;
    }

    .gradient-text {
        font-size: 1.5rem;
    }
}
