.contact_wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 1.25rem;
}

.top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    border-bottom: 1px solid grey;
    padding-bottom: 2.5rem;
}

.top .vision {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: 1rem;
}

.gradient_container {
    align-self: flex-end;
}

.gradient-text {
    background: linear-gradient(to bottom, #ffffff, #000000);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-size: 3.5rem;
    margin: 0;
    font-family: "Manrope", sans-serif;
    letter-spacing: 5px;
    text-align: center;
}

/* Responsive Styles */

@media (min-width: 768px) {
    .contact_wrapper {
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 1.25rem;
    }

    .top {
        flex: 45%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .top .vision {
        width: 200px;
        height: 150px;
        margin-top: 0;
    }
    .gradient_container {
        align-self: center;
    }
    .bottom {
        flex: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
