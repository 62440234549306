.nav {
    width: 100%;
    display: flex;
    height: 100px;
    padding-top: 30px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    position: absolute;
    z-index: 100;
}

.nav .links > p {
    font-family: "Manrope", sans-serif;
    font-size: 0.75rem;
    letter-spacing: 5px;
    color: #ffffff;
}

.nav .links {
    cursor: pointer;
    align-self: flex-end;
}

.nav .logo {
    width: 10rem;
    height: 2rem;
    cursor: pointer;
    object-fit: contain;
}

/* Responsive Styles */

@media (max-width: 768px) {
    .nav {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 150px;
        padding: 0;
        padding-bottom: 5px;
    }

    .nav .links {
        align-self: center;
    }

    .nav .links > p {
        font-size: 0.75rem;
        letter-spacing: 3.5px;
    }

    .nav .logo {
        width: 10rem;
        height: 1.5rem;
        margin-bottom: 1rem;
    }
}
