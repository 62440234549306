.bg {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-behavior: smooth;
}

.text_onbg {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 36vw;
    margin: auto;
}

.text_onbg > p {
    font-family: "Poppins", sans-serif;
    color: white;
    font-size: 0.8vw;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    letter-spacing: 2px;
    word-spacing: 2px;
}

.text_onbg .heading {
    color: white;
    font-family: "Manrope", sans-serif;
    font-size: 1vw;
    letter-spacing: 0.95vw;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
    .text_onbg {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        width: 100%;
        text-align: center;
    }
    .text_onbg > p {
        font-family: "Manrope", sans-serif;
        color: white;
        font-size: 3.5vw;
        margin-bottom: 1rem;
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
    }
}
