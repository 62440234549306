.mobile_bg {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-behavior: smooth;
}

.mobile_text_onbg {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 70vw;
    margin: auto;
}

.mobile_text_onbg > p {
    font-family: "Manrope", sans-serif;
    color: white;
    font-size: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.mobile_text_onbg .heading {
    color: white;
    font-family: "Manrope", sans-serif;
    font-size: 0.9rem;
    letter-spacing: 1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.para4_text {
    color: white;
    font-family: "Manrope", sans-serif;
    font-size: 0.85rem;
    letter-spacing: 0.5rem;
}
